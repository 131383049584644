import * as React from 'react';
import styled from 'styled-components';

interface IMagnification {
  className: string;
}

export const MagnificationGlass: React.FC<IMagnification> = ({ className }) => {
  return (
    <StyledMagnificationGlass className={className}>
      <svg
        width="31"
        height="31"
        viewBox="0 0 31 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.7224 27.2685L20.605 18.1494C22.0073 16.3081 22.7964 13.9408 22.7964 11.3976C22.7964 5.08477 17.7116 0 11.3988 0C5.08597 0 -0.000488281 5.08645 -0.000488281 11.3993C-0.000488281 17.7121 5.08428 22.7969 11.3971 22.7969C13.9403 22.7969 16.2188 22.0078 18.1489 20.6055L27.268 29.7246C27.6181 30.0747 28.0571 30.2506 28.496 30.2506C28.935 30.2506 29.3722 30.0747 29.7241 29.7246C30.4244 29.0226 30.4244 27.9704 29.7224 27.2685L29.7224 27.2685ZM11.3988 19.2903C7.01432 19.2903 3.50776 15.7837 3.50776 11.3993C3.50776 7.01481 7.01432 3.50825 11.3988 3.50825C15.7833 3.50825 19.2898 7.01481 19.2898 11.3993C19.2898 15.7821 15.7816 19.2903 11.3988 19.2903Z"
          fill=""
        />
      </svg>
    </StyledMagnificationGlass>
  );
};

const StyledMagnificationGlass: any = styled.div``;
