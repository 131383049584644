import * as React from 'react';

interface IIcon {
  className: string;
}

export const CardLogo: React.SFC<IIcon> = ({ className }) => {
  return (
    <svg viewBox="0 0 324 324" version="1.1" className={className}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="pm_mark_squared" fill="#000000" fillRule="nonzero">
          <path
            d="M165,211.9 C155.6,207.9 153.6,204.3 153.6,195.1 L153.6,170.5 L173,170.5 C196.2,170.5 211.7,160.3 211.7,141 C211.7,121.7 196.2,111.5 172.9,111.5 L124,111.5 L124,111.8 C130.6,115.5 132.1,119.9 132.1,129.4 L132.1,194.4 C132.1,203.9 130.7,208.2 124,212 L124,212.3 L165,212.3 L165,211.9 Z M153.7,115.3 L166.2,115.3 C182.7,115.3 190.4,125.8 190.4,141.1 C190.4,156.4 182.8,166.9 166.2,166.9 L153.7,166.9 L153.7,115.3 Z M213.2,83.2 L219.6,99.6 L220.7,99.6 L227.1,83.2 L232.3,98.8 L237.1,98.8 L228.1,71.8 L227,71.8 L220.2,89.1 L213.4,71.8 L212.3,71.8 L203.3,98.8 L208,98.8 L213.2,83.2 Z M0.1,0.1 L0.1,323.6 L323.6,323.6 L323.6,0.1 L0.1,0.1 Z M316.2,316.2 L7.5,316.2 L7.5,7.5 L316.2,7.5 L316.2,316.2 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
};
