import { gql } from '@apollo/client';

export const LEISURE_SEARCH_QUERY = gql`
  query LeisureSearch($input: SearchInput) {
    search(input: $input) {
      result {
        properties {
          total
          aggregations {
            propertyTypes {
              key
              doc_count
            }
            facilityTypes {
              key
              doc_count
            }
            locationTypes {
              key
              doc_count
            }
            counties {
              key
              doc_count
            }
            municipalities {
              key
              doc_count
            }
            municipalityAreas {
              key
              doc_count
            }
          }
          list {
            id
            address
            place
            type
            assignmentType
            sold
            checklistpoints {
              info {
                pmExclusive
                teaser
              }
            }
            freetext {
              list {
                finnLocalArea
              }
            }
            office {
              info {
                urltag
              }
            }
            images {
              first {
                url
              }
              list {
                url
              }
            }
            location {
              municipality
              municipalityArea
              county
            }
            price {
              info {
                price
                priceTo
              }
            }
            area {
              prom
              promTo
              bra
              braTo
            }
            units {
              sold
              total
              available
            }
          }
        }
      }
    }
  }
`;
