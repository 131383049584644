import * as React from 'react';

import { useQuery } from '@apollo/client';
import { ClipLoader } from 'react-spinners';
import styled, { css, useTheme } from 'styled-components';

import { LEISURE_SEARCH_QUERY } from '../../../../client/__graphql__/queries/leisure';

import { useDebounce } from '../../../utils/hooks/useDebounce';
import { PropertyCard } from '../../ui/cards/Property';
import { Range } from '../../ui/form/Range';
import { Select } from '../../ui/form/Select';
import { Pagination } from '../../ui/pagination/Pagination';
import SearchFilterTag from '../../ui/search/SearchFilterTag';

import { Heading } from '../../ui/text/Heading';
import { useScrollToElement } from '../../../utils/hooks/useScrollToElement';
import { mapStringArrayToArrayKeyObject } from '../fritid/utils/mapArrayToArrayKeyObject';
import { filterArrayByMatchCount } from '../fritid/utils/filterArrayByMatchCount';
import { SearchInput } from '../../ui/form/SearchInput';
import { useLeisureSearchContext } from './context';
import { capitalizeFirstLetter } from './utils/capitalizeFirstLetter';

interface IAggregatedType {
  key: string;
  doc_count: number;
}

interface IDefaultLabel {
  defaultLabel: string;
  county: string;
  searchLocation: string;
}

const Sok = React.forwardRef<HTMLDivElement, IDefaultLabel>((props, ref) => {
  const { defaultLabel, county, searchLocation } = props ?? {};
  const resultRef = React.useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const { scrollTo } = useScrollToElement();

  const {
    query,
    page,
    updatePage,
    updateQuery,
    mountain,
    toggleMountain,
    sea,
    toggleSea,
    sold,
    toggleSold,
    counties,
    onCountyClick,
    municipalities,
    selectedEstateTypes,
    onSelectEstateType,
    onRemoveEstateType,
    selectedFacilities,
    onSelectFacility,
    onRemoveFacility,
    price,
    updatePrice,
    size,
    updateSize
  } = useLeisureSearchContext();

  const [itemsPerPage] = React.useState(6);

  const [propertyTypesAggregated, setPropertyTypesAggregated] = React.useState<
    IAggregatedType[]
  >([]);
  const [facilityTypesAggregated, setFacilityTypesAggregated] = React.useState<
    IAggregatedType[]
  >([]);
  const [countiesAggregated, setCountiesAggregated] = React.useState<
    IAggregatedType[]
  >([]);
  const [municipaltiesAggregated, setMunicipaltiesAggregated] = React.useState<
    IAggregatedType[]
  >([]);

  const debouncedQuery = useDebounce<typeof query>(query, 300);
  const debouncedPrice = useDebounce<typeof price>(price, 300);
  const debouncedSize = useDebounce<typeof size>(size, 300);

  const { data, loading } = useQuery(LEISURE_SEARCH_QUERY, {
    variables: {
      input: {
        query: debouncedQuery,
        searchIn: [
          {
            type: 'property',
            pagination: { page: page + 1, limit: itemsPerPage }
          }
        ],
        filter: {
          property: {
            objectTypeAggregated: {
              types: mapStringArrayToArrayKeyObject(selectedEstateTypes)
            },
            facilityTypeAggregated: {
              types: mapStringArrayToArrayKeyObject(selectedFacilities)
            },
            location: [
              {
                id: county,
                county,
                locations: []
              }
            ],
            subLocations: [
              {
                id: capitalizeFirstLetter(searchLocation),
                county,
                municipalityArea: capitalizeFirstLetter(searchLocation)
              }
            ],
            price: debouncedPrice,
            size: debouncedSize,
            sold: true,
            sort: 'PUBLISHEDNEWOLD'
          }
        }
      }
    },

    /* Setting propertyTypesAggregated states to the same as propertyTypes */
    onCompleted: ({ search }) => {
      const propertyTypes =
        search?.result?.properties?.aggregations?.propertyTypes;
      const facilityTypes =
        search?.result?.properties?.aggregations?.facilityTypes;
      const counties = search?.result?.properties?.aggregations?.counties;

      const municipalities =
        search?.result?.properties?.aggregations?.municipalities;

      if (propertyTypesAggregated.length === 0 && propertyTypes) {
        setPropertyTypesAggregated(propertyTypes);
      }
      if (facilityTypesAggregated.length === 0 && facilityTypes) {
        setFacilityTypesAggregated(facilityTypes);
      }
      if (countiesAggregated.length === 0 && counties) {
        setCountiesAggregated(counties);
      }
      if (municipaltiesAggregated.length === 0 && municipalities) {
        setMunicipaltiesAggregated(municipalities);
      }
    }
  });

  return (
    <SokStyle ref={ref}>
      <Heading center tag="h1" className="solgte-i-omraade">
        Solgte eiendommer i dette området
      </Heading>
      <Searchfilter>
        <div className="align-bottom">
          <Select
            label="Eiendomstype"
            name="boligtype"
            placeholder="Eiendomstype"
            className="text-label-sok"
            value={0}
            onChange={(e) => {
              onSelectEstateType(e.target.value);
              e.target.selectedIndex = 0;
            }}
          >
            <option disabled value="0">
              Eiendomstype
            </option>
            {filterArrayByMatchCount(propertyTypesAggregated).map((item) => (
              <option value={item.key} key={item.key}>
                {item.key}
              </option>
            ))}
          </Select>
          {selectedEstateTypes.length > 0 ? (
            <SearchFilterTagContainer>
              {selectedEstateTypes.map((item) => (
                <SearchFilterTag
                  key={`tag_${item}`}
                  label={item}
                  onClick={() => onRemoveEstateType(item)}
                />
              ))}
            </SearchFilterTagContainer>
          ) : null}
        </div>
        <div className="align-bottom">
          <Select
            label="Fasiliteter"
            name="fasiliteter"
            className="text-label-sok"
            placeholder="Fasiliteter"
            value={0}
            onChange={(e) => {
              onSelectFacility(e.target.value);
              e.target.selectedIndex = 0;
            }}
          >
            <option disabled value="0">
              Fasiliteter
            </option>
            {facilityTypesAggregated.map((item) => (
              <option value={item.key} key={item.key}>
                {item.key}
              </option>
            ))}
          </Select>
          {selectedFacilities.length > 0 ? (
            <SearchFilterTagContainer>
              {selectedFacilities.map((item) => (
                <SearchFilterTag
                  key={`tag_${item}`}
                  label={item}
                  onClick={() => onRemoveFacility(item)}
                />
              ))}
            </SearchFilterTagContainer>
          ) : null}
        </div>
        <div className="align-bottom">
          <SearchInput
            label="Fritekstsøk"
            placeholder="Område, postnummer og adresse"
            className="fritekstsøklabel text-label-sok"
            value={query}
            onChange={(e) => updateQuery(e.target.value)}
          />
        </div>
        <div className="align-bottom">
          <Range
            className="rangelabel"
            label="Prisantydning"
            asCurrency
            range={{
              min: 1000000,
              max: 10000000,
              step: 1000,
              onChange: ([from, to]) => updatePrice({ from, to }),
              value: [price.from, price.to]
            }}
            unit="kr"
          />
        </div>
        <div className="align-bottom">
          <Range
            className="rangelabel"
            label="Størrelse"
            range={{
              min: 30,
              max: 400,
              step: 10,
              onChange: ([from, to]) => updateSize({ from, to }),
              value: [size.from, size.to]
            }}
            unit="m&sup2;"
          />
        </div>
      </Searchfilter>
      {loading ? (
        <SearchLoading>
          <ClipLoader size={30} color={theme.colors.primary} />
        </SearchLoading>
      ) : (
        <>
          {data?.search?.result?.properties?.list?.length === 0 ? (
            <SearchNoResults>
              <p>Ingen resultater funnet..</p>
            </SearchNoResults>
          ) : (
            <>
              <LeisureProperties ref={resultRef}>
                {data?.search?.result?.properties?.list?.map((item: any) => (
                  <PropertyCard
                    className="propertycard"
                    key={item.id}
                    item={item}
                    currentPage={1}
                  />
                ))}
              </LeisureProperties>
              {data?.search?.result?.properties?.total > 0 && !loading ? (
                <Pagination
                  className="pagination"
                  center
                  currentPage={page}
                  maxPages={
                    data?.search?.result?.properties?.total / itemsPerPage
                  }
                  total={data?.search?.result?.properties?.total}
                  itemsPerPage={itemsPerPage}
                  pageRangeDisplayed={1}
                  pageOnChange={({ selected }) => {
                    if (typeof scrollTo === 'function') {
                      scrollTo({
                        ref: resultRef,
                        distanceTop: 100,
                        behaviour: 'auto'
                      });
                      updatePage(selected);
                    }
                  }}
                />
              ) : null}
            </>
          )}
        </>
      )}
    </SokStyle>
  );
});

const SearchLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 200px;
  width: 100%;
  min-height: 550px;
`;

const SearchNoResults = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 550px;
  padding-top: 200px;
`;

const SearchFilterTagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 2.5em 0 1em;
`;

const SokStyle = styled.div`
  .checkgroupbox {
    display: grid;
    grid-template-rows: 1fr;
    grid-column-gap: 1px;
    margin-left: 16px;
  }
  .solgte-i-omraade {
    color: ${({ theme }) => theme.colors.primary};
    padding: 1.5em 1em;
  }
  .text-label-sok {
    margin-top: 10px;
  }
  @media all and (min-width: 800px) {
    .solgte-i-omraade {
      color: ${({ theme }) => theme.colors.primary};
      padding: 1em 2em 1em;
    }
  }

  @media all and (min-width: 600px) {
    .pagination {
      margin-top: 2em;
    }
    .checkgroupbox {
      grid-auto-flow: column;
      grid-row-gap: 1em;
    }
    .fritekstsøklabel {
      margin-top: unset;
    }
    .text-label-sok {
      margin-top: unset;
    }
  }
`;

const BaseGrid = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2em;
  grid-row-gap: 2em;

  @media all and (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media all and (min-width: 960px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Searchfilter = styled.div`
  ${BaseGrid}
  .select-wrapper {
    margin-bottom: 0;
  }
  .align-bottom {
    align-self: start;
  }
`;

const LeisureProperties = styled.div`
  ${BaseGrid}
  margin-top: 2em;
  min-height: 550px;
  .propertycard {
    text-align: center;
  }
`;

export default Sok;
