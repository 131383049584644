import React from 'react';
import styled from 'styled-components';

const SpaceStyle = styled.div`
  position: relative;
`;

interface ISpace {
  style?: any;
  className?: string;
  height?: any;
}
const Space: React.FC<ISpace> = ({ height, style, children, className }) => {
  return (
    <>
      <SpaceStyle className={className} style={{ ...style, height }} />
    </>
  );
};

export default Space;
