import * as React from 'react';
import styled from 'styled-components';
import { Range as RCRange } from 'rc-slider';
import { currency } from '../../../utils/format/currency';

interface IRange {
  asCurrency?: boolean;
  unit: string;
  label: string;
  range: {
    min: number;
    max: number;
    step: number;
    onChange: (value: number[]) => void;
    value: [number, number];
  };
  className: any;
}

export const Range: React.FC<IRange> = ({
  asCurrency,
  unit,
  label,
  range,
  className
}) => {
  return (
    <RangeWrapper className={className}>
      <label>{label}</label>
      <SliderWrap>
        <RCRange
          min={range.min}
          max={range.max}
          step={range.step}
          onChange={range.onChange}
          value={range.value}
          railStyle={{ backgroundColor: '#252525' }}
          trackStyle={[{ backgroundColor: '#e8c893' }]}
          handleStyle={[
            {
              backgroundColor: '#000',
              borderColor: '#e8c893',
              borderWidth: '3px',
              marginLeft: '6px'
            },
            {
              backgroundColor: '#000',
              borderColor: '#e8c893',
              borderWidth: '3px'
            }
          ]}
        />
      </SliderWrap>
      <RangeInfo>
        {range.value[0] === 1000000 && 'Under'}{' '}
        {asCurrency
          ? currency({ number: range.value[0], seperator: ' ' })
          : range.value[0]}{' '}
        - {range.value[1] === 10000000 && 'Over'}{' '}
        {asCurrency
          ? currency({ number: range.value[1], seperator: ' ' })
          : range.value[1]}{' '}
        <span>{unit}</span>
      </RangeInfo>
    </RangeWrapper>
  );
};

const RangeWrapper = styled.div`
  color: white;
`;
const SliderWrap = styled.div`
  margin: 10px 0;
`;
const RangeInfo = styled.div`
  margin-top: 20px;
  font-size: 0.9em;
`;
