import * as React from 'react';
import styled from 'styled-components';

import { Container } from '../../layout/Container';
import { Section } from '../../layout/Section';
import { Paragraph } from '../../ui/text/Paragraph';
import { animated } from 'react-spring';
import { useFormTransition } from '../../../utils/hooks/useFormTransition';
import { Heading } from '../../ui/text/Heading';
import { ButtonGroup } from '../../ui/button/ButtonGroup';
import { Button } from '../../ui/button/Button';
import { ButtonGroupColumn } from '../../ui/button/ButtonGroupColumn';
import Sok from './Sok';
import { useParams } from 'react-router';
import { createLeadMutation } from '../../../../client/__graphql__/mutations';
import { useMutation, useQuery } from '@apollo/client';
import { ILeadInput, ILeadResponse } from '../../../__types__/global';
import { useForm } from '../../../utils/hooks/useForm';
import { Form, FormRow, FormWrapper } from '../../ui/form/Form';
import { FormError } from '../../ui/form/FormError';
import { Input } from '../../ui/form/Input';
import { ButtonLink } from '../../ui/link/ButtonLink';
import { routeConfig } from '../../../__config__/routes';
import { Thanks } from '../../layout/Thanks';
import StyledPropertySold from '../../ui/cards/StyledPropertySold';
import {
  SOLD_PROPERTY_QUERY,
  ZIP_TO_OFFICE_QUERY
} from '../../../../client/__graphql__/queries';
import NoMatch from '../404Old';
import { LeisureSearchContextProvider } from './context';
import { Hero, HeroContent } from '../../ui/hero/Index';
import Space from '../../ui/space/Space';

interface ISolgte {
  variables: any;
  urltagArray?: string[];
  urltag?: string;
  defaultLabel?: string;
}
interface IParams {
  office: string;
  sguid: any;
}

type SolgteParams = IParams | ISolgte;

const Solgte: React.FC<SolgteParams> = () => {
  const { office, sguid } = useParams<IParams>();
  const searchRef = React.useRef<HTMLDivElement>(null);
  const [posted, setPosted] = React.useState(false);
  const formRef = React.useRef(null);
  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });

  const MAIL_MUTATION = createLeadMutation({
    mail: {
      response: `{
              success
            }`
    }
  });

  const [mail, { loading: mailLoading, error }] = useMutation<
    ILeadResponse,
    ILeadInput
  >(MAIL_MUTATION, {
    onCompleted: ({ lead }) => {
      if (lead.mail?.success) {
        setPosted(true);
      }
    }
  });

  /* SOLD PROPERTY START */
  const { data: sold, loading } = useQuery(SOLD_PROPERTY_QUERY, {
    fetchPolicy: 'cache-first',
    variables: {
      input: {
        sguid: sguid,
        sold: true
      }
    }
  });

  // Henter ut postnumemr fra den solgte eiendommen
  const zip = sold?.property?.zip ?? {};

  const { data: zipData, loading: zipLoad } = useQuery(ZIP_TO_OFFICE_QUERY, {
    fetchPolicy: 'cache-first',
    variables: {
      input: {
        /* setter det uthentede postnummeret fra den solgte eiendom inn i query for å få tilgang til urltag basert på postnummer for kontoret */
        query: zip,
        searchIn: [{ type: 'office', pagination: { page: 1, limit: 1 } }]
      }
    }
  });

  /* Henter så ut kontorets urltag basert på postnummeret fra den solgte eiendommen */
  const { urltag } = zipData?.search?.result?.offices?.list?.[0] ?? {};
  const id = sold?.property?.id;

  const officeNameUrl = urltag
    ?.replace(/æ/gi, 'ae')
    .replace(/ø/gi, 'o')
    .replace(/&/gi, '')
    .replace(/å/gi, 'aa')
    .replace(/\s+/g, '')
    .toLowerCase()
    .trim();

  const { county, searchLocation } = sold?.property?.location ?? {};
  const brokerList = sold?.property?.brokers?.list;

  const brokerNameCV =
    /* Sjekker om meglerlisten har innhold */
    brokerList && brokerList.length > 0
      ? (() => {
          const responsibleBroker = brokerList.find(
            (broker: any) => broker.responsible
          );
          /* Vis ansvarlig megler */
          if (responsibleBroker) {
            return (
              responsibleBroker.name
                .split(' ')
                .join('-')
                .replace(/æ/gi, 'ae')
                .replace(/ø/gi, 'o')
                .replace(/&/gi, '')
                .replace(/å/gi, 'aa')
                .replace(/\s+/g, '')
                // Hvis navn er p. fjernet .
                .replace(/\.+/g, (match: any, offset: any, broker: any) =>
                  broker.toLowerCase().includes('p') ? '' : match
                )
                .toLowerCase()
                .trim()
            );
          } else {
            /* hvis ikke finnes, hvis annen megler koblet til oppdraget */
            brokerList.name
              .split(' ')
              .join('-')
              .replace(/æ/gi, 'ae')
              .replace(/ø/gi, 'o')
              .replace(/&/gi, '')
              .replace(/å/gi, 'aa')
              .replace(/\s+/g, '')
              .toLowerCase()
              .trim();
          }
          return null;
        })()
      : null;

  const { fields, getFields, handleSubmit, handleChange } = useForm({
    fields: [
      {
        type: 'text',
        name: 'name',
        placeholder: 'Eks. Ola Nordmann',
        value: '',
        label: 'Navn *',
        required: true
      },
      {
        type: 'tel',
        name: 'phone',
        placeholder: 'Eks. 00000000',
        value: '',
        label: 'Telefon *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'email',
        name: 'email',
        placeholder: 'Eks. ole@mail.no',
        value: '',
        label: 'E-post *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'text',
        name: 'zip',
        placeholder: 'Eks. 1234',
        value: '',
        label: 'Postnummer *',
        required: true
      },
      {
        type: 'text',
        name: 'address',
        placeholder: 'Eks. Adresseveien 23',
        value: '',
        label: 'Adresse',
        required: true
      }
    ],
    submit: () => {
      const preparedFields: any = getFields(fields);
      mail({
        variables: {
          input: {
            ...preparedFields,
            referer: location.pathname,
            leadType: 'EPOST',
            mailType: 95
          }
        }
      });
    }
  });

  return (
    <>
      {!loading &&
      sold &&
      sold?.property?.images?.first?.url &&
      sold?.property?.address ? (
        <SolgteStyle>
          <StyledPropertySold className="soldmark" style={{ top: '66px' }} />
          <Hero
            className="content-height-1000 padding-top-50 padding-bottom-80 height hero-content-flex-bottom"
            size="cover"
            position="bottom"
            imageUrl={sold?.property?.images?.first?.url}
            opacity={0.6}
            alt="bilde"
          >
            <HeroContentWrapper className="">
              <HeroContent>
                <Space style={{ minHeight: '280px' }} />
                <Heading tag="h1" center={true} className="solgte">
                  {sold?.property?.address}, <br />
                  {sold?.property?.zip} {sold?.property?.place}
                </Heading>
              </HeroContent>

              <DescriptionInsideTheHeroContainer>
                <Container>
                  <ButtonGroupWrapper>
                    <Container>
                      <TopHeroDescription className="text-container">
                        <Paragraph center={true} className="top-text">
                          Vi har nylig solgt denne eiendommen. Kontakt oss
                          dersom du ønsker å vite mer om akkurat dette
                          eiendomssalget <br /> eller hvis du har spørsmål i
                          forbindelse med salg av egen eiendom.
                        </Paragraph>
                      </TopHeroDescription>
                    </Container>
                    <ButtonGroup className="btn-group">
                      {sold?.property?.brokers?.list ? (
                        <>
                          <ButtonGroupColumn className="space-bottom full-width btn-group-column">
                            <Paragraph
                              className="btn-description"
                              center={true}
                            >
                              Ønsker du å vite mer om salget av akkurat denne
                              eiendommen?
                            </Paragraph>
                            <ButtonGrid className="grid">
                              <ButtonWrapper>
                                <Button
                                  type="primary"
                                  colorTheme="gold"
                                  as="link"
                                  to={`/megler/${brokerNameCV}`}
                                  center={true}
                                  target="_blank"
                                >
                                  Klikk her
                                </Button>
                              </ButtonWrapper>
                            </ButtonGrid>
                          </ButtonGroupColumn>
                        </>
                      ) : null}
                      <ButtonGroupColumn className="space-bottom full-width btn-group-column">
                        <Paragraph className="btn-description" center={true}>
                          Har du spørsmål eller trenger du råd i forbindelse med
                          salg av eiendom i samme området som denne eiendommen
                          ble solgt?
                        </Paragraph>
                        <ButtonGrid>
                          <Button
                            type="secondary"
                            colorTheme="gray"
                            center={true}
                            as="link"
                            to={`/${officeNameUrl}`}
                            target="_blank"
                          >
                            Kontakt oss
                          </Button>
                        </ButtonGrid>
                      </ButtonGroupColumn>
                      <ButtonGroupColumn className="space-bottom full-width btn-group-column">
                        <Paragraph className="btn-description" center={true}>
                          Ønsker du å se mer av eiendommen?
                        </Paragraph>
                        <ButtonGrid>
                          <Button
                            type="secondary"
                            colorTheme="gray"
                            center={true}
                            as="link"
                            to={`/${id}`}
                            target="_blank"
                          >
                            Eiendommen
                          </Button>
                        </ButtonGrid>
                      </ButtonGroupColumn>
                    </ButtonGroup>
                  </ButtonGroupWrapper>
                </Container>
                <TopGradient className="topgradient" />
              </DescriptionInsideTheHeroContainer>
            </HeroContentWrapper>
          </Hero>

          <Container>
            <Wrapper style={{ ...fadeOut, marginTop: '2em' }}>
              <LeisureSearchContextProvider>
                <Sok
                  defaultLabel={office}
                  county={county}
                  searchLocation={searchLocation}
                  ref={searchRef}
                />
              </LeisureSearchContextProvider>
            </Wrapper>
          </Container>

          <FormWrapper style={fadeOut} ref={formRef}>
            <Container>
              <Section
                className="form-section"
                style={{ paddingBottom: '10px', paddingTop: '66px' }}
              >
                <Heading tag="h1" center={true}>
                  Kontakt
                </Heading>
                <Paragraph center={true}>
                  Har du spørsmål eller trenger du råd i forbindelse med salg av
                  eiendom i samme området som disse eiendommene ble solgt?
                </Paragraph>
              </Section>

              <Form
                onSubmit={handleSubmit}
                noValidate
                style={{ paddingBottom: '50px' }}
              >
                {error &&
                  error.graphQLErrors.map((error, index) => (
                    <FormError key={`error_${index}`} message={error.message} />
                  ))}
                {fields.map((item, index) => {
                  return (
                    <FormRow key={`field_${index}`}>
                      <Input
                        type={item?.type}
                        name={item?.name}
                        label={item?.label}
                        labelAfter={item?.labelAfter}
                        placeholder={item?.placeholder}
                        value={item?.value}
                        onChange={handleChange}
                        error={item?.error}
                      />
                    </FormRow>
                  );
                })}
                <ButtonGroup>
                  <Button
                    type="primary"
                    colorTheme="gold"
                    disabled={loading}
                    loading={loading}
                  >
                    Kontakt meg
                  </Button>
                  <ButtonLink
                    as="a"
                    href={routeConfig.personvern.url}
                    className="privacy-link-mobile"
                  >
                    Personvernpolicy
                  </ButtonLink>
                </ButtonGroup>
              </Form>
            </Container>
          </FormWrapper>
          <Thanks style={fadeIn}>
            <Section className="form-section">
              <Heading tag="h1" center={true}>
                Tusen takk
              </Heading>
              <Paragraph center={true}>
                En eiendomsmegler vil ta kontakt med deg så snart som mulig.
              </Paragraph>
            </Section>
          </Thanks>
        </SolgteStyle>
      ) : (
        <NoMatch />
      )}
    </>
  );
};

const DescriptionInsideTheHeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;

  @media all and (min-width: 800px) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
`;

const TopHeroDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .text-container {
    text-align: center;
  }
  .top-text {
    position: absolute;
    padding: 2em 1em;
    z-index: 1;
  }

  @media all and (min-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    .text-container {
      text-align: center;
    }
    .top-text {
      position: absolute;
      z-index: 1;
      margin-bottom: 0;
      padding-top: 4em;
      padding-bottom: 0;
    }
  }
`;

const TopGradient = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -220px;
  top: 61%;
  background: linear-gradient(
    359deg,
    #171719 0.62%,
    rgba(23, 23, 25, 0) 187.75%
  );

  @media all and (min-width: 400px) {
    bottom: -182px;
  }
  @media all and (min-width: 440px) {
    top: 58%;
    bottom: -223px;
  }
  @media all and (max-width: 440px) {
    top: 54%;
  }
  @media all and (min-width: 801px) {
    top: 77%;
  }
  @media all and (min-width: 600px) {
    bottom: -186px;
  }
  @media all and (min-width: 830px) {
    bottom: -224px;
  }
`;

const ButtonWrapper = styled.div``;

const ButtonGroupWrapper = styled.div`
  .btn-group {
    position: relative;
    top: 11em;
  }

  .btn-group-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
    margin-bottom: 0;
  }
  .btn-description {
    color: ${({ theme }) => theme.colors.primary};
    padding: 0 1em;
  }

  @media all and (min-width: 600px) {
    .btn-group-column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      z-index: 1;
      margin-bottom: 0;
      z-index: 1;
    }
  }

  .btn-description {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const SolgteStyle = styled.div`
  .soldmark {
    width: 70px;
    height: 70px;
  }

  .solgte {
    margin: 0;
    color: ${({ theme }) => theme.colors.primary};
    word-wrap: break-word;
  }

  .content-height-1000 {
    margin-top: -6em;
  }

  @media all and (min-width: 600px) {
    .soldmark {
      width: 100px;
      height: 100px;
    }

    .solgte {
      padding-bottom: 1em;
    }
  }
`;

const HeroContentWrapper = styled.div``;

const ButtonGrid = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 0;

  @media all and (min-width: 600px) {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 16px;
  }
`;

const Wrapper = styled(animated.div)``;

export default Solgte;
