import * as React from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
import { currency } from '../../../utils/format/currency';
import { CardLogo } from '../logo/card/Index';
import StyledPropertySoldWithDate from './StyledPropertySold';
import StyledPropertySold from './StyledPropertySold';
import { CarouselButtonRightWithBackground } from '../button/CarouselButtonRightWithBackground';
import { CarouselButtonLeftWithBackground } from '../button/CarouselButtonLeftWithBackground';
import { ImageCarouselBulletsWithAnimation } from '../carousel/ImageCarouselBulletsWithAnimation';

interface ITab {
  key?: any;
  item: any;
  currentPage: number;
  className?: any;
  date?: string;
  searchResult?: boolean;
}

interface IArea {
  area: any;
}

interface IUnits {
  sold: number;
  available: number;
  total: number;
  teaser?: boolean;
}

const Area: React.SFC<IArea> = ({ area }) => {
  let str = '';
  if (area.prom && area.promTo && area.prom > 0 && area.prom !== area.promTo) {
    str = `${Math.trunc(area.prom)}m - ${Math.trunc(area.promTo)}m`;
  } else if (
    area.prom &&
    area.promTo &&
    area.prom > 0 &&
    area.prom === area.promTo
  ) {
    str = `${Math.trunc(area.prom)}m`;
  } else {
    str = `${Math.trunc(area.bra ? area.bra : 0)}m`;
  }
  return (
    <StyledPropertyArea>
      <span>{str}</span>
      {str ? <sup>2</sup> : null}
    </StyledPropertyArea>
  );
};

const Subunits: React.SFC<IUnits> = ({ total, sold, available, teaser }) => {
  let str = '';
  if (teaser) {
    str = 'Kommer for salg';
  } else {
    if (total > 0 && sold === total) {
      str = 'Utsolgt';
    } else {
      if (sold > total / 2) {
        str = `${sold} av ${total} solgt`;
      } else {
        str = `${available} av ${total} ledige`;
      }
    }
  }

  if (str !== '') {
    return <StyledPropertyUnits>{str}</StyledPropertyUnits>;
  }
  return null;
};

export const PropertyCard: React.SFC<ITab> = React.memo(
  ({ item, currentPage, className, date, searchResult }) => {
    const [loaded, setLoaded] = React.useState(false);
    const [missingImage, setMissingImage] = React.useState(false);
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [currentDirection, setCurrentDirection] = React.useState<
      null | string
    >(null);

    React.useEffect(() => {
      if (loaded) {
        setLoaded(false);
      }
    }, [currentPage]);

    React.useEffect(() => {
      if (
        item &&
        item.images &&
        item.images.first &&
        item.images.first.url === null
      ) {
        setMissingImage(true);
      } else {
        setMissingImage(false);
      }
    }, [item, currentPage]);

    const prev = () => {
      const newIndex = currentIndex - 1 < 0 ? 0 : currentIndex - 1;
      setCurrentIndex(newIndex);
      setCurrentDirection('prev');
    };
    const next = () => {
      const newIndex =
        currentIndex + 1 > item.images.list.length - 1
          ? item.images.list.length - 1
          : currentIndex + 1;

      setCurrentDirection('next');
      setCurrentIndex(newIndex);
    };

    return (
      <StyledPropertyItem
        className="property-item"
        onClick={() => {
          if (typeof window !== 'undefined' && item?.id) {
            window.location.href = `https://privatmegleren.no/${item.id}`;
          }
        }}
      >
        <StyledPropertyImageWrapper>
          <CarouselButtonLeftWithBackground
            id={item?.id}
            className="left"
            onClick={(e) => {
              e.stopPropagation();
              prev();
            }}
          />
          <StyledPropertyImage loaded={loaded}>
            <LazyLoad height={250} throttle={100} offset={50}>
              <>
                {item.images.list.map((item: any, index: any) => {
                  if (index === currentIndex) {
                    return <img key={item.id} src={item?.url} alt="bilde" />;
                  } else {
                    return null;
                  }
                })}
                <ImageCarouselBulletsWithAnimation
                  className="bullets"
                  images={item.images.list}
                  currentIndex={currentIndex}
                  currentDirection={currentDirection}
                />
              </>
            </LazyLoad>

            <CarouselButtonRightWithBackground
              id={item?.id}
              className="right"
              onClick={(e) => {
                e.stopPropagation();
                next();
              }}
            />
          </StyledPropertyImage>

          {missingImage ? <CardLogo className="pm-logo" /> : null}
          {item?.assignmentType === 'NYBYGG' && !item.sold ? (
            <StyledPropertyNybygg />
          ) : null}
          {item?.sold ? <StyledPropertySold date={item.soldDate} /> : null}
          {item?.assignmentType === 'NYBYGG' &&
          item?.checklistpoints?.info?.teaser ? (
            <Subunits sold={0} total={0} available={0} teaser={true} />
          ) : null}
          {item?.assignmentType === 'NYBYGG' &&
          item?.checklistpoints?.info?.teaser === false &&
          item?.sold !== true &&
          item?.units?.available > 0 ? (
            <Subunits
              sold={item.units.sold}
              total={item.units.total}
              available={item.units.available}
            />
          ) : null}
        </StyledPropertyImageWrapper>
        <StyledPropertyInfo className={className}>
          <StyledPropertyLocation className="county">
            {item?.location?.municipalityArea}
            <br />
            {item?.place}
          </StyledPropertyLocation>
          <StyledPropertyData>
            <span>{item?.address}</span>
            <span>
              {item?.type}{' '}
              {item?.assignmentType !== 'NYBYGG' && item?.area?.prom ? (
                <>
                  - {item.area.prom}m<sup>2</sup>
                </>
              ) : null}
            </span>
            {item?.assignmentType === 'NYBYGG' ? (
              <Area area={item.area} />
            ) : null}
          </StyledPropertyData>
          <StyledPropertyPrice className="price">
            {item?.price?.info?.priceTo ? (
              <>
                {currency({
                  number: item.price.info.price
                })}{' '}
                - {currency({ number: item.price.info.priceTo })},-
              </>
            ) : item?.price?.info?.price ? (
              <>{currency({ number: item.price.info.price })},-</>
            ) : (
              '-'
            )}
          </StyledPropertyPrice>
        </StyledPropertyInfo>
      </StyledPropertyItem>
    );
  },
  (prevProps, nextProps) => {
    if (prevProps?.item?.id === nextProps?.item?.id) {
      return true;
    }
    return false;
  }
);

const StyledPropertyArea = styled.div`
  display: flex;
  flex-flow: row;
`;

const StyledPropertyImageWrapper: any = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
  background: rgb(15, 15, 15);
  &:hover {
    .left,
    .right {
      display: block;
    }
  }
  .left,
  .right {
    display: none;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    &.lazy-image {
      position: absolute;
      width: 1px;
      height: 1px;
      z-index: -1;
    }
  }
  .pm-logo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 60px;
    height: 60px;
    path {
      fill: rgb(232, 200, 147);
    }
  }
`;

const StyledPropertyImage: any = styled.div`
  transition: opacity 0.2s ease-in-out;
  position: relative;
  width: 100%;
  height: 250px;
  img {
    object-fit: cover;
    width: 100%;
    max-height: 100%;
  }
`;

const StyledPropertyNybygg = styled.div`
  position: absolute;
  top: 0;
  left: 20px;
  width: 70px;
  height: 70px;
  background: url('https://cdn.reeltime.no/pm_assets/img/nyeboliger.jpg')
    no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1;
`;

const StyledPropertyUnits = styled.div`
  background-color: rgba(0, 0, 0, 0.65);
  bottom: 1em;
  color: #fff;
  left: 0;
  padding: 0.5em 1em;
  position: absolute;
  text-align: center;
  width: 100%;
`;

const StyledPropertyInfo = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 1.5em;
`;

const StyledPropertyData = styled.div`
  margin: 10px 0 0 0;
  display: flex;
  flex-flow: column;
  width: 100%;
  line-height: 1.6;
  color: white;
`;

const StyledPropertyLocation = styled.div`
  transition: color 0.2s ease-in-out;
  display: block;
  font-size: 1em;
  line-height: 1.1em;
  color: #e8c893;
`;

const StyledPropertyPrice = styled.div`
  transition: color 0.2s ease-in-out;
  margin-top: 10px;
  display: block;
  font-size: 1em;
  color: #e8c893;
`;

const StyledPropertyItem = styled.div`
  transition: background 0.2s ease-in-out;
  display: flex;
  grid-column: span 1;
  flex-flow: column;
  background: #1c1c1c;
  overflow: hidden;
  .county {
    word-break: break-all;
  }
  &:hover {
    cursor: pointer;
    background: #e5c88f;
    .county {
      color: black;
    }
    .price {
      color: black;
    }
  }
`;

export const PropertyListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  width: 100%;
  &::after {
    content: '';
    flex: auto;
  }
  .property-item {
    width: calc(33.3333% - 10px);
    &:nth-last-child(1) {
      margin-left: 15px;
    }
  }
  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      minmax(calc(100% / 3 - 10px), calc(100% / 3 - 10px))
    );
    grid-gap: 15px;
    .property-item {
      width: 100%;
      &:nth-last-child(1) {
        margin-left: auto;
      }
    }
  }
  @media screen and (max-width: 580px) {
    .property-item {
      width: 100%;
      &:nth-last-child(1) {
        margin-left: auto;
      }
    }
    @supports (display: grid) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  @media screen and (min-width: 580px) and (max-width: 960px) {
    .property-item {
      width: calc(50% - 10px);
      &:nth-last-child(1) {
        margin-left: 15px;
      }
    }
    @supports (display: grid) {
      grid-template-columns: repeat(
        auto-fit,
        minmax(calc(100% / 2 - 10px), calc(100% / 2 - 10px))
      );
      .property-item {
        width: 100%;
        &:nth-of-type(even) {
          margin-left: 0;
        }
        &:nth-last-child(1) {
          margin-left: auto;
        }
      }
    }
  }
`;
