import React from 'react';
import styled from 'styled-components';
import { TypeButton } from '../../containers/boligsok/TypeButton';
import { CloseIcon } from '../icons/Close';

interface ISearchFilterTag extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
}

const SearchFilterTag: React.FC<ISearchFilterTag> = ({ label, onClick }) => {
  return (
    <SearchFilterTagStyle>
      <TypeButton value={label} close={onClick}></TypeButton>
    </SearchFilterTagStyle>
  );
};

const SearchFilterTagStyle = styled.div`
  padding-bottom: 4px;
`;

export default SearchFilterTag;
