import * as React from 'react';
import { useHistory } from 'react-router';
import qs from 'qs';

interface ICounty {
  id: string;
  county: string;
}

interface IMunicipality {
  id: string;
  county: string;
  municipalityArea: string;
}

interface IUrlState {
  /** search */
  q: string;
  /** mountain */
  m: string;
  /** sea */
  s: string;
  /** sold */
  so: string;
  /** counties */
  a: string;
  /** municipalities */
  as: { a: string; as: string }[];
  /** estate types */
  o: string;
  /** facilities */
  f: string;
  /** price */
  pr: { from: number; to: number };
  /** size */
  sz: { from: number; to: number };
  /** page */
  p: number;
}

interface ILeisureSearchContext {
  reset: () => void;
  page: number;
  updatePage: (page: number) => void;
  query: string;
  updateQuery: (value: string) => void;
  mountain: boolean;
  toggleMountain: () => void;
  sea: boolean;
  toggleSea: () => void;
  sold: boolean;
  toggleSold: () => void;
  counties: ICounty[];
  isActiveCounty: (key: string) => boolean;
  onCountyClick: (key: string) => void;
  municipalities: IMunicipality[];
  isActiveMunicipality: (key: string) => boolean;
  onMunicipalityClick: (args: { county: string; municipality: string }) => void;
  selectedEstateTypes: string[];
  onSelectEstateType: (key: string) => void;
  onRemoveEstateType: (key: string) => void;
  selectedFacilities: string[];
  onSelectFacility: (key: string) => void;
  onRemoveFacility: (key: string) => void;
  price: { from: number; to: number };
  updatePrice: (args: { from: number; to: number }) => void;
  size: { from: number; to: number };
  updateSize: (args: { from: number; to: number }) => void;
}

const LeisureSearchContext = React.createContext<ILeisureSearchContext>({
  reset: () => {},
  page: 0,
  updatePage: () => {},
  query: '',
  updateQuery: () => {},
  mountain: false,
  toggleMountain: () => {},
  sea: false,
  toggleSea: () => {},
  sold: false,
  toggleSold: () => {},
  counties: [],
  isActiveCounty: () => false,
  onCountyClick: () => {},
  municipalities: [],
  isActiveMunicipality: () => false,
  onMunicipalityClick: () => {},
  selectedEstateTypes: [],
  onSelectEstateType: () => {},
  onRemoveEstateType: () => {},
  selectedFacilities: [],
  onSelectFacility: () => {},
  onRemoveFacility: () => {},
  price: { from: 0, to: 0 },
  updatePrice: () => {},
  size: { from: 0, to: 0 },
  updateSize: () => {}
});

export enum Action {
  RESET = 'RESET',
  UPDATE_PAGE = 'UPDATE_PAGE',
  UPDATE_QUERY = 'UPDATE_QUERY',
  TOGGLE_MOUNTAIN = 'TOGGLE_MOUNTAIN',
  TOGGLE_SEA = 'TOGGLE_SEA',
  TOGGLE_SOLD = 'TOGGLE_SOLD',
  TOGGLE_COUNTIES = 'TOGGLE_COUNTIES',
  TOGGLE_MUNICIPALITIES = 'TOGGLE_MUNICIPALITIES',
  SELECT_ESTATE_TYPE = 'SELECT_ESTATE_TYPE',
  REMOVE_SELECTED_ESTATE_TYPE = 'REMOVE_SELECTED_ESTATE_TYPE',
  SELECT_FACILITY = 'SELECT_FACILITY',
  REMOVE_SELECTED_FACILITY = 'REMOVE_SELECTED_FACILITY',
  UPDATE_PRICE = 'UPDATE_PRICE',
  UPDATE_SIZE = 'UPDATE_SIZE'
}

type ResetAction = {
  type: Action.RESET;
};

type UpdatePageAction = {
  type: Action.UPDATE_PAGE;
  payload: number;
};

type UpdateQueryAction = {
  type: Action.UPDATE_QUERY;
  payload: { value: string; page: number };
};

type ToggleMountainAction = {
  type: Action.TOGGLE_MOUNTAIN;
  page: number;
};

type ToggleSeaAction = {
  type: Action.TOGGLE_SEA;
  page: number;
};

type ToggleSoldAction = {
  type: Action.TOGGLE_SOLD;
  page: number;
};

type ToggleCountiesAction = {
  type: Action.TOGGLE_COUNTIES;
  payload: { key: string; page: number };
};

type ToggleMuniciapalityAction = {
  type: Action.TOGGLE_MUNICIPALITIES;
  payload: { county: string; municipality: string; page: number };
};

type SelectEstateTypeAction = {
  type: Action.SELECT_ESTATE_TYPE;
  payload: { value: string; page: number };
};

type RemoveSelectedEstateTypeAction = {
  type: Action.REMOVE_SELECTED_ESTATE_TYPE;
  payload: { value: string; page: number };
};

type SelectFacilityTypeAction = {
  type: Action.SELECT_FACILITY;
  payload: { value: string; page: number };
};

type RemoveSelectedFacilityAction = {
  type: Action.REMOVE_SELECTED_FACILITY;
  payload: { value: string; page: number };
};

type UpdatePriceAction = {
  type: Action.UPDATE_PRICE;
  payload: { from: number; to: number; page: number };
};

type UpdateSizeAction = {
  type: Action.UPDATE_SIZE;
  payload: { from: number; to: number; page: number };
};

type ReducerState = {
  page: number;
  query: string;
  mountain: boolean;
  sea: boolean;
  sold: boolean;
  counties: { id: string; county: string }[];
  municipalities: { id: string; county: string; municipalityArea: string }[];
  selectedEstateTypes: string[];
  selectedFacilities: string[];
  price: { from: number; to: number };
  size: { from: number; to: number };
};

type ReducerAction =
  | UpdatePageAction
  | ResetAction
  | UpdateQueryAction
  | ToggleCountiesAction
  | ToggleMuniciapalityAction
  | ToggleMountainAction
  | ToggleSeaAction
  | ToggleSoldAction
  | SelectEstateTypeAction
  | RemoveSelectedEstateTypeAction
  | SelectFacilityTypeAction
  | RemoveSelectedFacilityAction
  | UpdatePriceAction
  | UpdateSizeAction;

const initialState: ReducerState = {
  page: 0,
  query: '',
  mountain: false,
  sea: false,
  sold: false,
  counties: [],
  municipalities: [],
  selectedEstateTypes: [],
  selectedFacilities: [],
  price: { from: 1000000, to: 10000000 },
  size: { from: 30, to: 400 }
};

export const LeisureSearchContextProvider: React.FC = React.memo(
  ({ children }) => {
    const init = React.useRef(true);
    const history = useHistory();
    const [state, dispatch] = React.useReducer(
      (state: ReducerState, action: ReducerAction) => {
        let isChecked;
        switch (action.type) {
          case Action.RESET:
            init.current = false;
            return initialState;
          case Action.UPDATE_PAGE:
            init.current = false;
            return { ...state, page: action.payload };
          case Action.UPDATE_QUERY:
            init.current = false;
            return {
              ...state,
              query: action.payload.value,
              page: action.payload.page || 0
            };
          case Action.TOGGLE_MOUNTAIN:
            init.current = false;
            return {
              ...state,
              mountain: !state.mountain,
              page: action.page || 0
            };
          case Action.TOGGLE_SEA:
            init.current = false;
            return { ...state, sea: !state.sea, page: action.page || 0 };
          case Action.TOGGLE_SOLD:
            init.current = false;
            return { ...state, sold: !state.sold, page: action.page || 0 };
          case Action.SELECT_ESTATE_TYPE:
            init.current = false;
            return {
              ...state,
              selectedEstateTypes: [
                ...state.selectedEstateTypes,
                action.payload.value
              ],
              page: action.payload.page || 0
            };
          case Action.REMOVE_SELECTED_ESTATE_TYPE:
            init.current = false;
            return {
              ...state,
              selectedEstateTypes: state.selectedEstateTypes.filter(
                (item) => item !== action.payload.value
              ),
              page: action.payload.page || 0
            };
          case Action.SELECT_FACILITY:
            init.current = false;
            return {
              ...state,
              selectedFacilities: [
                ...state.selectedFacilities,
                action.payload.value
              ],
              page: action.payload.page || 0
            };
          case Action.REMOVE_SELECTED_FACILITY:
            init.current = false;
            return {
              ...state,
              selectedFacilities: state.selectedFacilities.filter(
                (item) => item !== action.payload.value
              ),
              page: action.payload.page || 0
            };
          case Action.UPDATE_PRICE:
            init.current = false;
            return {
              ...state,
              price: { from: action.payload.from, to: action.payload.to },
              page: action.payload.page || 0
            };
          case Action.UPDATE_SIZE:
            init.current = false;
            return {
              ...state,
              size: { from: action.payload.from, to: action.payload.to },
              page: action.payload.page || 0
            };
          case Action.TOGGLE_COUNTIES:
            init.current = false;
            isChecked =
              state.counties.findIndex(
                (item) => item.id === action.payload.key
              ) > -1;
            if (isChecked) {
              const municipalities = state.municipalities.filter(
                (item) => item.county !== action.payload.key
              );
              const counties = state.counties.filter(
                (item) => item.id !== action.payload.key
              );
              return {
                ...state,
                municipalities,
                counties,
                page: action.payload.page || 0
              };
            } else {
              return {
                ...state,
                counties: [
                  ...state.counties,
                  { id: action.payload.key, county: action.payload.key }
                ],
                page: action.payload.page || 0
              };
            }
          case Action.TOGGLE_MUNICIPALITIES:
            init.current = false;
            isChecked =
              state.municipalities.findIndex(
                (item) => item.municipalityArea === action.payload.municipality
              ) > -1;
            if (isChecked) {
              return {
                ...state,
                municipalities: state.municipalities.filter(
                  (item) =>
                    item.municipalityArea !== action.payload.municipality
                ),
                page: action.payload.page || 0
              };
            } else {
              return {
                ...state,
                municipalities: [
                  ...state.municipalities,
                  {
                    id: action.payload.county,
                    county: action.payload.county,
                    municipalityArea: action.payload.municipality
                  }
                ],
                page: action.payload.page || 0
              };
            }
          default:
            throw new Error('unknown action');
        }
      },
      initialState
    );

    const isActiveCounty = (key: string) => {
      return state.counties.findIndex((item) => item.id === key) > -1;
    };

    const isActiveMunicipality = (key: string) => {
      return (
        state.municipalities.findIndex(
          (item) => item.municipalityArea === key
        ) > -1
      );
    };

    const getStateFromUrl = (): IUrlState => {
      return qs.parse(location.search.slice(1)) as unknown as IUrlState;
    };

    function urlParamToArray<Type = any>(
      input: string | any[],
      cb: (value: Type) => void
    ) {
      if (input) {
        if (typeof input === 'string') {
          const params = input.split(',');
          if (params.length > 0) {
            params.forEach((value) => {
              cb(value as unknown as Type);
            });
          }
        } else if (Array.isArray(input)) {
          const params = input;
          params.forEach((value) => {
            cb(value as unknown as Type);
          });
        }
      }
    }

    const isChecked = (value: string) => {
      return value === 'true';
    };

    /* Update state with state from query params */
    React.useEffect(() => {
      const { q, m, s, so, a, as, o, f, pr, sz, p } = getStateFromUrl();
      const page = Number(p);

      urlParamToArray<typeof q>(q, (value) => {
        dispatch({ type: Action.UPDATE_QUERY, payload: { value, page } });
      });
      urlParamToArray<typeof a>(a, (value) => {
        dispatch({
          type: Action.TOGGLE_COUNTIES,
          payload: { key: value, page }
        });
      });
      urlParamToArray<(typeof as)[0]>(as, (value) => {
        dispatch({
          type: Action.TOGGLE_MUNICIPALITIES,
          payload: { county: value.a, municipality: value.as, page }
        });
      });
      urlParamToArray<typeof o>(o, (value) => {
        dispatch({ type: Action.SELECT_ESTATE_TYPE, payload: { value, page } });
      });
      urlParamToArray<typeof f>(f, (value) => {
        dispatch({ type: Action.SELECT_FACILITY, payload: { value, page } });
      });
      if (p) {
        dispatch({ type: Action.UPDATE_PAGE, payload: Number(p) });
      }
      if (pr?.from && pr.to) {
        dispatch({
          type: Action.UPDATE_PRICE,
          payload: { from: Number(pr.from), to: Number(pr.to), page }
        });
      }
      if (sz?.from && sz.to) {
        dispatch({
          type: Action.UPDATE_SIZE,
          payload: { from: Number(sz.from), to: Number(sz.to), page }
        });
      }
      if (isChecked(m)) {
        dispatch({ type: Action.TOGGLE_MOUNTAIN, page });
      }
      if (isChecked(s)) {
        dispatch({ type: Action.TOGGLE_SEA, page });
      }
      if (isChecked(so)) {
        dispatch({ type: Action.TOGGLE_SOLD, page });
      }
    }, []);

    /* Update url with query params if filter is changed */
    React.useEffect(() => {
      if (!init.current) {
        const urlState: IUrlState = {
          q: state.query,
          m: state.mountain.toString(),
          s: state.sea.toString(),
          so: state.sold.toString(),
          a: state.counties.map((item) => item.county).join(','),
          as: state.municipalities.map((item) => ({
            a: item.county,
            as: item.municipalityArea
          })),
          o: state.selectedEstateTypes.map((item) => item).join(','),
          f: state.selectedFacilities.map((item) => item).join(','),
          pr: state.price,
          sz: state.size,
          p: state.page
        };

        history.replace({
          pathname: window.location.pathname,
          search: qs.stringify(urlState)
        });
      }
    }, [init.current, state, state.mountain, state.sea, state.sold]);

    return (
      <LeisureSearchContext.Provider
        value={{
          reset: () => dispatch({ type: Action.RESET }),
          query: state.query,
          page: state.page,
          updatePage: (page) =>
            dispatch({ type: Action.UPDATE_PAGE, payload: page }),
          updateQuery: (value) =>
            dispatch({
              type: Action.UPDATE_QUERY,
              payload: { value, page: 0 }
            }),
          mountain: state.mountain,
          toggleMountain: () =>
            dispatch({ type: Action.TOGGLE_MOUNTAIN, page: 0 }),
          sea: state.sea,
          toggleSea: () => dispatch({ type: Action.TOGGLE_SEA, page: 0 }),
          sold: state.sold,
          toggleSold: () => dispatch({ type: Action.TOGGLE_SOLD, page: 0 }),
          counties: state.counties,
          isActiveCounty,
          onCountyClick: (key) =>
            dispatch({
              type: Action.TOGGLE_COUNTIES,
              payload: { key, page: 0 }
            }),
          municipalities: state.municipalities,
          isActiveMunicipality,
          onMunicipalityClick: (args) =>
            dispatch({
              type: Action.TOGGLE_MUNICIPALITIES,
              payload: { ...args, page: 0 }
            }),
          selectedEstateTypes: state.selectedEstateTypes,
          onSelectEstateType: (key) =>
            dispatch({
              type: Action.SELECT_ESTATE_TYPE,
              payload: { value: key, page: 0 }
            }),
          onRemoveEstateType: (key) =>
            dispatch({
              type: Action.REMOVE_SELECTED_ESTATE_TYPE,
              payload: { value: key, page: 0 }
            }),
          selectedFacilities: state.selectedFacilities,
          onSelectFacility: (key) =>
            dispatch({
              type: Action.SELECT_FACILITY,
              payload: { value: key, page: 0 }
            }),
          onRemoveFacility: (key) =>
            dispatch({
              type: Action.REMOVE_SELECTED_FACILITY,
              payload: { value: key, page: 0 }
            }),
          price: state.price,
          updatePrice: ({ from, to }) =>
            dispatch({
              type: Action.UPDATE_PRICE,
              payload: { from, to, page: 0 }
            }),
          size: state.size,
          updateSize: ({ from, to }) =>
            dispatch({
              type: Action.UPDATE_SIZE,
              payload: { from, to, page: 0 }
            })
        }}
      >
        {children}
      </LeisureSearchContext.Provider>
    );
  }
);

export const useLeisureSearchContext = () => {
  return React.useContext<ILeisureSearchContext>(LeisureSearchContext);
};
