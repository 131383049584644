import * as React from 'react';
import styled from 'styled-components';
import { MagnificationGlass } from '../icons/Magnification';

interface ISearchInput extends React.HTMLAttributes<Omit<HTMLInputElement, 'type'>> {
  value: string;
  label?: string;
  labelAfter?: string;
  error?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchInput: React.FC<ISearchInput> = React.memo((props) => {
  return (
    <StyledInputWrapper>
      {props.label ? (
        <label className={props.className}>
          {props.label} {props.labelAfter ? <span>{props.labelAfter}</span> : null}
        </label>
      ) : null}
      <StyledInputInsideWrapper>
        <MagnificationGlass className="input-search-icon" />
        <StyledInput
          type="text"
          name="searchfield"
          className="search-input"
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          error={props.error}
          tabIndex={0}
        />
      </StyledInputInsideWrapper>
    </StyledInputWrapper>
  );
});

const StyledInputInsideWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  .search-input {
    padding-left: 2.5em;
  }

  .input-search-icon {
    position: absolute;
    left: 1em;
    width: 1.2em;
    height: 1.2em;
    z-index: 1;

    svg {
      width: inherit;
      height: inherit;
      path {
        fill: ${(props: any) => props.theme.input.placeholder};
      }
    }
  }
`;

const StyledInputWrapper = styled.div`
  width: 100%;
  label {
    display: block;
    font-size: 1rem;
    color: ${(props) => props.theme.input.label};
    margin-bottom: 10px;

    span {
      color: ${(props) => props.theme.input.labelAfter};
    }
  }
`;

const StyledInput: any = styled.input`
  display: block;
  padding: 15px;
  width: 100%;
  background: ${(props: any) => props.theme.input.background};
  font-size: ${(props) => props.theme.input.font.size};
  font-weight: 400;
  color: white;
  border: ${(props: any) => (props.error ? `solid 2px ${props.theme.input.error}` : 'solid 2px transparent')};
  outline: none;
  border-radius: 0;

  &[type='submit'] {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
  &::-webkit-input-placeholder {
    color: ${(props: any) => props.theme.input.placeholder};
  }
`;
